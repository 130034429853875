import { UseFormReturn } from 'react-hook-form';
import Check from '../../icons/Check';
import { PropsWithChildren } from 'react';

interface CheckboxProps {
  name: string;
  required?: boolean;
  value?: boolean | string;
  className?: string;
  disabled?: boolean;
  id?: string;
  methods: UseFormReturn<any, any>;
  onKeyPress?: (args: any) => any;
  onClick?: (args: any) => any;
  tabIndex?: number;
  onBlur?: (args: any) => any;
}

const Checkbox: React.FC<PropsWithChildren<CheckboxProps>> = ({
  name,
  required,
  children,
  value,
  className,
  disabled,
  id,
  methods,

  onKeyPress,
  onClick,
  onBlur,
  tabIndex,
}) => {
  if (!className) {
    className =
      'rounded-sm peer-checked:bg-primary peer-checked:border-primary border-gray7';
  }
  const {
    register,
    formState: { errors, isSubmitted },
  } = methods;

  const errorMessage = errors[name]?.message || '';
  const inputProps =
    value === true
      ? { value: true as any }
      : value
      ? { value: value as any }
      : {};
  return (
    <div className={`relative flex items-start`}>
      <div className="w-10 h-10">
        <input
          data-testid={id || name}
          tabIndex={tabIndex != undefined ? tabIndex : 0}
          id={id || name}
          type="checkbox"
          className="relative z-10 self-stretch w-10 h-10 opacity-0 cursor-pointer peer"
          {...register(name, {
            required: required ? 'This field is required' : false,
            ...(value == true ? inputProps : {}),
          })}
          {...(value != true ? inputProps : {})}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onClick={onClick}
          onBlur={onBlur}
        />
        <div
          className={`${
            Boolean(errorMessage && isSubmitted) ? 'border-red' : ''
          } ${className} absolute  h-5 w-5 border-solid border-2 top-[4px] left-2 hover:cursor-pointer peer-focus:outline`}
        >
          <Check className="absolute w-4 h-4 text-white cursor-pointer inset" />
        </div>
      </div>
      <label
        data-testid={`label-${id || name}`}
        htmlFor={id || name}
        className={`${
          Boolean(errorMessage && isSubmitted) ? 'text-red' : 'text-black'
        } text-sm md:text-lg font-light font-base cursor-pointer`}
      >
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
