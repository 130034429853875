import { User, UserCredential } from 'firebase/auth';
import { AuthType } from '../enums';
import { useAuth } from '../providers/authProvider';
import nookies from 'nookies';
import { useAuthType } from '../providers/authTypeState';
import { useEffect } from 'react';
import createUserRequest from '../api/createUserRequest';
import once from 'lodash/once';
import { useRegion } from '../providers/regionProvider';

const createUserOnce = once(createUserRequest);
const useCreateUser = async (
  user: User | undefined,
  uid: string | undefined,
  data: any,
  result: UserCredential | undefined | null | void,
  condition = true,
) => {
  const { setSeeker, setUser } = useAuth();
  const { setAuthType } = useAuthType();
  const { setUserLocationInfo } = useRegion();

  useEffect(() => {
    if (user && result && condition) {
      handleResultChange();
    }
  }, [result]);

  const handleResultChange = async () => {
    if (!uid || !user) return;
    const { phoneNumber, firstName, lastName, canText, email } = data;
    try {
      const seeker = await createUserOnce(uid, {
        email,
        phone: phoneNumber,
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        zipcode: '',
        signUpLocation: window.location.pathname,
        canText: canText,
        uid,
      });
      setSeeker(seeker);
      setUserLocationInfo({
        location: seeker.location,
        zipcode: seeker.zipcode,
        zipcodeLocation: seeker.zipcodeLocation,
      });
      setUser(user);
      setAuthType(AuthType.None);
      const token = await user.getIdToken();
      nookies.set(undefined, 'token', token, { path: '/' });
    } catch (err: any) {
      console.log(err.message);
    }
  };
};

export default useCreateUser;
