import { IconProps } from '../../../lib/types';

const Spinner: React.FC<IconProps> = ({ className }) => {
  return (
    <div className="flex justify-center items-center">
      <div
        className={`${className} spinner-border animate-spin inline-block border-4 rounded-full border-l-transparent`}
        role="status"
      ></div>
    </div>
  );
};
export default Spinner;
