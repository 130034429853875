import { IconProps } from '../../../lib/types';

const Check: React.FC<IconProps> = ({ className, onClick }) => {
  return (
    <div onClick={onClick} tabIndex={onClick ? 0 : -1}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
      </svg>
    </div>
  );
};
export default Check;
