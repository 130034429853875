import CreateSeekerDTO from 'wwg-utilities/dist/dtos/create-seeker.dto';
import auth from '../config/firebase';
import { getCookie } from '../cookies';
import apiClient from './axiosClient';
import CareerSeeker from '../models/career-seeker.model';

const createUserRequest = async (
  uid: string,
  seeker: CreateSeekerDTO & { isPhoneLogin?: boolean },
): Promise<CareerSeeker> => {
  const token = (await auth.currentUser?.getIdToken()) || getCookie('token');
  return apiClient
    .post(`seekers/${uid}`, seeker, {
      headers: {
        'Authorization-WWG': `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export default createUserRequest;
