import { AlertState } from '../../../lib/enums';
import AlertIcon from '../../icons/Alert';

type AlertProps = {
  message: string;
  type: AlertState;
};

const Alert: React.FC<AlertProps> = ({ message, type }) => {
  if (!message) return <></>;
  return (
    <div
      data-testid="alert"
      className={`my-4 p-2 ${
        type === 'success' ? 'bg-primary' : 'bg-primary-dark'
      } text-white grid grid-cols-alert leading-5 items-center`}
    >
      <span className="flex items-center">
        <AlertIcon className="w-6 h-6 stroke-highlight" />
        &nbsp;&nbsp;
      </span>
      <p>{message}</p>
    </div>
  );
};
export default Alert;
