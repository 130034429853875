import { UseFormReturn, ValidationRule } from 'react-hook-form';

type TextInputProps = {
  name: string;
  label: string;
  id: string;
  type: string;
  customValidationMethod?: (value: string) => boolean | string;
  customFormattingMethod?: (value: string) => string;
  required?: boolean;
  pattern?: ValidationRule<RegExp>;
  value?: string;
  description?: string;
  onChange?: (args: any) => void;
  disabled?: boolean;
  onFocus?: (args?: any) => void;
  onKeyDown?: (args?: any) => void;
  onBlur?: (args?: any) => void;
  methods:  UseFormReturn<any, any>;

};

const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  id,
  type,
  required = false,
  pattern,
  value,
  customValidationMethod,
  customFormattingMethod,
  description,
  onChange,
  disabled,
  onFocus,
  onKeyDown,
  onBlur,
  methods
}) => {
  // Pass state up to the parent form
  const {
    register,
    formState,
    formState: { isSubmitted, isDirty },
    setValue,
    trigger,
  } = methods;
  const errorMessage = formState.errors[name]?.message || '';

  const handleChange = (event: any) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    // Format input if necessary
    if (customFormattingMethod) {
      const formatted = customFormattingMethod(value);
      setValue(name, formatted);
    } else {
      setValue(name, value);
    }
    if (onChange) {
      onChange(event);
    }
    trigger(name);
  };

  return (
    <div className="flex-1 py-1 mx-1 font-heading">
      <div className="relative w-full mt-6 mb-2">
        <input
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder=" "
          id={id}
          className={`peer leading-loose p-3 rounded-md  w-full focus:outline-none focus:shadow-md ${
            errorMessage && isSubmitted ? 'border-red' : 'border-primary-dark'
          } ${
            errorMessage && isSubmitted
              ? 'focus-within:border-red'
              : 'focus-within:border-primary'
          } border-2 ${type === 'phone' ? 'pl-7' : ''}`}
          type={type}
          {...register(name, {
            required: required ? 'This field is required' : false,
            pattern: pattern,
            validate: customValidationMethod,
            onChange: handleChange,
            value: value,
            onBlur: onBlur,
            
          })}
          disabled={disabled}
        />
        {/* If it's a phone input, show +1 country code */}
        {type == 'phone' ? (
          <p className="absolute flex items-center left-0 p-1 pr-2 m-0.5 z-10 rounded-tl-md rounded-bl-md top-0 bottom-0 bg-slate-200">
            +1
          </p>
        ) : (
          ''
        )}
        <label
          className={`leading-loose peer-focus:font-semibold peer-focus:-translate-y-10 peer-focus:text-xs  peer-focus:left-0 absolute top-3.5 ${
            type == 'phone' ? 'left-10' : 'left-3.5'
          } ${
            errorMessage && isSubmitted
              ? 'text-red'
              : isDirty
              ? 'text-primary-dark'
              : 'text-gray2'
          }`}
          htmlFor={id}
        >
          {label}
        </label>
      </div>
      {Boolean(errorMessage && isSubmitted) && (
        <label
          data-testid={`input-error-${name}`}
          className="block text-xs text-red"
        >
          {errorMessage as string}
        </label>
      )}
      {description && <p className="text-sm italic">{description}</p>}
    </div>
  );
};
export default TextInput;
