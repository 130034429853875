import CareerSeeker from 'wwg-utilities/dist/collections/career-seeker';
import apiClient from './axiosClient';

export const getUserByEmailRequest = async (
  email: string,
): Promise<CareerSeeker> => {
  return apiClient
    .get(`seeker-helpers/${email}`)
    .then((res) => res.data || null)
    .catch((err) => console.log(err.message));
};

export default getUserByEmailRequest;
