import React, { createRef, useEffect, useState } from 'react';
import {
  ConfirmationResult,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithRedirect,
  UserCredential,
} from 'firebase/auth';
import { FieldValues, useForm, FormProvider } from 'react-hook-form';
import { parsePhoneNumber } from 'libphonenumber-js';

import ReactCodeInput from 'react-verification-code-input';

import Link from 'next/link';
import { toast } from 'react-toastify';
import {
  phoneNumberFormatter,
  phoneNumberValidator,
} from 'wwg-utilities/dist/validation';
import {
  AlertState,
  AuthType,
  LoginType,
  RegistrationType,
} from '../../../../lib/enums';
import { useAuthType } from '../../../../lib/providers/authTypeState';
import auth from '../../../../lib/config/firebase';
import useCreateUser from '../../../../lib/hooks/createUser';
import Alert from '../../../shared/Alert';
import SpinnerIcon from '../../../icons/Spinner';
import ArrowLeft from '../../../icons/ArrowLeft';
import TextInput from '../../../shared/TextInput';
import Checkbox from '../../../shared/Checkbox';
import Google from '../../../icons/Google';

let appVerifier: RecaptchaVerifier;

type AuthFormProps = {
  setLoginType?: React.Dispatch<React.SetStateAction<LoginType>>;
  setRegistrationType?: React.Dispatch<React.SetStateAction<RegistrationType>>;
};
const PhoneAuthForm: React.FC<AuthFormProps> = ({ setLoginType }) => {
  const methods = useForm();
  const { authType, setAuthType } = useAuthType();
  const provider = new GoogleAuthProvider();
  const handleGoogleSignIn = () => {
    signInWithRedirect(auth, provider).catch(() => {
      toast.error('Error creating user');
    });
  };

  // Tells us whether or not we're on the verify SMS code screen
  const [verify, setVerify] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  // The result from Firebase if sending phone number was successful
  const [confirmationResult, setConfirmationResult] = useState(
    {} as ConfirmationResult,
  );
  const [submissionError, setSubmissionError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneAuthResult, setPhoneAuthResult] =
    useState<UserCredential | void>();

  useCreateUser(
    phoneAuthResult?.user,
    phoneAuthResult?.user.uid,
    methods.getValues(),
    phoneAuthResult,
    authType === AuthType.Register,
  );

  // Must have recaptcha for Firebase phone auth
  const recaptchaRef = createRef<HTMLDivElement>();

  useEffect(() => {
    // Inititalize the recaptcaha
    if (verify) return;
    appVerifier = new RecaptchaVerifier(
      'phoneLoginRecaptchaEl',
      {
        size: 'invisible',
      },
      auth,
    );
  }, [verify]);

  // On error, we need to completely remove and reset the recaptcha element
  const resetCaptcha = () => {
    // appVerifier.clear();
    const current = recaptchaRef.current;
    if (current) {
      current.innerHTML = '<div id="phoneLoginRecaptchaEl"></div>';
    }
  };

  const handlePhoneNumberSubmit = async (data: FieldValues) => {
    setSubmissionError('');
    setIsSubmitting(true);

    // TODO  check if the authType === AuthType.Login
    // and then query for the user by phone number
    // if they don't exist, direct them to register (so we can get their name)
    // firebase handles both login and sign up the same.
    const { phoneNumber } = data;

    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'US');
    // Firebase only accepts 11 digit phone numbers
    const formattedNumber = parsedPhoneNumber.number;

    signInWithPhoneNumber(auth, formattedNumber, appVerifier)
      .then((confirmationResult) => {
        resetCaptcha();

        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setVerify(true);
        setConfirmationResult(confirmationResult);
      })
      .catch((err) => {
        resetCaptcha();
        setSubmissionError(err.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    methods.trigger('agreedToTerms');
    methods.trigger('canText');
  }, [methods.watch('agreedToTerms'), methods.watch('canText')]);

  const handleVerificationSubmit = () => {
    setSubmissionError('');
    setIsSubmitting(true);

    confirmationResult
      .confirm(verificationCode)
      .catch((err) => {
        resetCaptcha();
        setSubmissionError(err.message);
      })
      .then(async (userCred) => {
        setPhoneAuthResult(userCred);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  if (verify) {
    return (
      <div className="p-6">
        <FormProvider {...methods}>
          <Alert message={submissionError} type={AlertState.Error}></Alert>

          <form onSubmit={methods.handleSubmit(handleVerificationSubmit)}>
            <h2 className="my-2 text-xl font-bold leading-6 text-gray-900 font-heading text-primary-dark justify-evenly align-stretch">
              Enter the 6 digit verification code sent to your phone
            </h2>
            <div className="my-6">
              <ReactCodeInput
                fields={6}
                autoFocus={true}
                type="number"
                onChange={setVerificationCode}
                className="mx-auto font-heading"
              />
            </div>
            <button className="flex px-4 py-2 mx-auto my-3 font-bold bg-white border-2 rounded-full disabled:cursor-not-allowed font-heading hover:text-white hover:bg-green border-green text-green">
              {isSubmitting ? <SpinnerIcon className="w-6 h-6" /> : ''}
              Verify
            </button>
          </form>
          <button
            onClick={() => {
              setSubmissionError('');
              setVerify(false);
            }}
            className="flex items-center my-8 font-heading w-content"
          >
            <ArrowLeft className="h-5 w5"></ArrowLeft>&nbsp;Back
          </button>
        </FormProvider>
      </div>
    );
  }
  return (
    <div className="p-6">
      <FormProvider {...methods}>
        <Alert message={submissionError} type={AlertState.Error}></Alert>
        {authType == AuthType.Login && (
          <h2 className="text-xl font-bold font-heading text-purple">
            Welcome back!
          </h2>
        )}
        <form
          className="flex flex-col py-1 justify-evenly align-stretch"
          onSubmit={methods.handleSubmit(handlePhoneNumberSubmit)}
        >
          {authType === AuthType.Register ? (
            <div className="flex flex-col md:flex-row">
              <TextInput
                methods={methods}
                id={'firstName'}
                label={'Enter your first name'}
                name="firstName"
                type="text"
                required={true}
              />
              <TextInput
                methods={methods}
                id={'lastName'}
                label={'Enter your last name'}
                name="lastName"
                type="text"
                required={true}
              />
            </div>
          ) : (
            ''
          )}

          <TextInput
            methods={methods}
            customValidationMethod={phoneNumberValidator}
            customFormattingMethod={phoneNumberFormatter}
            id={'phoneNumberInput'}
            label={'Enter your phone number'}
            name="phoneNumber"
            type="phone"
            required={true}
          />
          {authType == AuthType.Register && (
            <div className="block my-6 max-w-full w-[400px] mx-auto">
              <Checkbox methods={methods} name="canText">
                I allow <span className="font-bold">SMS updates.</span>
              </Checkbox>
              <Checkbox methods={methods} name="agreedToTerms" required={true}>
                By checking this box, you are agreeing to these&nbsp;
                <Link href="#" target="_blank" className="text-link">
                  terms
                </Link>{' '}
                and{' '}
                <Link href="#" target="_blank" className="text-link">
                  privacy policy.*
                </Link>
              </Checkbox>
            </div>
          )}
          <div data-testid="phone-submit-button">
            <button className="flex disabled:cursor-not-allowed my-3 font-heading font-bold py-2 px-4 mx-auto rounded-full border-2 hover:text-white hover:bg-green border-green text-green bg-white min-w-[235px] flex items-center justify-center">
              {isSubmitting ? (
                <SpinnerIcon className="w-6 h-6 mr-4 border-inherit" />
              ) : (
                ''
              )}
              Send Code
            </button>
          </div>
          <p className="p-4 text-xs text-grey-900 font-heading">
            By tapping Send Code, an SMS may be sent. Message & data rates may
            apply.
          </p>

          <div className="mt-4">
            {authType === AuthType.Login && setLoginType ? (
              <div data-testid="login-with-email">
                <button
                  className="flex disabled:cursor-not-allowed my-3 font-heading font-bold py-2 px-4 mx-auto rounded-full border-2 hover:text-white hover:bg-primary-dark border-primary-dark text-primary-dark bg-white  min-w-[235px] flex items-center justify-center"
                  onClick={() => {
                    setLoginType(LoginType.Email);
                  }}
                >
                  Log in with Email
                </button>
              </div>
            ) : (
              ''
            )}

            {authType === AuthType.Login ? (
              <div data-testid="login-with-google">
                <button
                  className="flex disabled:cursor-not-allowed my-3 font-heading font-bold py-2 px-4 mx-auto rounded-full border-2 bg-white min-w-[235px] flex items-center justify-center hover:text-white hover:fill-white hover:bg-primary-dark border-primary-dark text-primary-dark fill-primary-dark"
                  onClick={handleGoogleSignIn}
                >
                  <Google className="w-5 h-5" /> &nbsp;Continue with Google
                </button>
                <div className="flex flex-col text-center font-heading font-semi-bold ">
                  <p
                    onClick={() => {
                      setAuthType(AuthType.ResetPassword);
                    }}
                    className="my-1 cursor-pointer hover:text-gold"
                    tabIndex={1}
                  >
                    Forgot your password?
                  </p>
                  <p
                    tabIndex={1}
                    className="my-1 cursor-pointer hover:text-gold"
                    onClick={() => {
                      setAuthType(AuthType.Register);
                    }}
                  >
                    Need an account?
                  </p>
                </div>
              </div>
            ) : (
              ''
            )}

            <div ref={recaptchaRef}>
              <div
                id="phoneLoginRecaptchaEl"
                style={{ visibility: 'hidden' }}
              ></div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default PhoneAuthForm;
